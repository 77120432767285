export const formSteps = {
  stepOne: 0,
  stepTwo: 1,
  stepThree: 2,
  stepFour: 3,
  stepFinal: 4,
  stepLogin: 5,
  stepPreview: 6,
}

export const formStepsValues = Object.values(formSteps)
