import SContainer from '../../components/ui/s-container'
import SLink from '../../components/ui/s-link'
import STitle from '../../components/ui/s-title'
import SButton from '../../components/ui/s-button'
import router from '../../plugins/router'

import iconNotFound from '../../assets/images/icon_not_found.svg'
import iconInternalServerError from '../../assets/images/icon_internal_server_error.svg'

export default {
  name: 'Error',

  components: {
    SButton,
    SContainer,
    SLink,
    STitle,
  },

  props: {
    code: {
      type: Number,
      default: null,
    },
  },

  computed: {
    errorCode() {
      if (this.code === null) {
        return router.currentRoute.meta.code
      }

      return this.code
    },

    icon() {
      const icons = {
        404: iconNotFound,
        500: iconInternalServerError,
      }

      return icons[this.errorCode] || icons[500]
    },

    name() {
      const names = {
        404: this.$t('NOT_FOUND'),
        500: this.$t('INTERNAL_SERVER_ERROR'),
      }

      return names[this.errorCode] || names[500]
    },

    title() {
      const titles = {
        404: this.$t('NOT_FOUND_TITLE'),
        500: this.$t('INTERNAL_SERVER_ERROR_TITLE'),
      }

      return titles[this.errorCode] || titles[500]
    },

    subtitle() {
      const subtitles = {
        404: this.$t('NOT_FOUND_DESCRIPTION'),
        500: this.$t('INTERNAL_SERVER_ERROR_DESCRIPTION'),
      }

      return subtitles[this.errorCode] || subtitles[500]
    },

    description() {
      const descriptions = {
        404: this.$t('NOT_FOUND_DESCRIPTION_SECOND'),
        500: this.$t('INTERNAL_SERVER_ERROR_DESCRIPTION_SECOND'),
      }

      return descriptions[this.errorCode] || descriptions[500]
    },

    linkText() {
      const linkTexts = {
        404: this.$t('NOT_FOUND_BTN'),
        500: this.$t('INTERNAL_SERVER_ERROR_BTN'),
      }

      return linkTexts[this.errorCode] || linkTexts[500]
    },

    linkTo() {
      const links = {
        404: '/',
        500: 'content',
      }

      return links[this.errorCode] || links[500]
    },
  },
}
