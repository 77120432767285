import firebase, { Auth, DB, FieldValue } from '../../plugins/firebase'
import router from '../../plugins/router'
import Logger from '../../plugins/logger'
import Metrics from '../../plugins/metrics'
import i18n from '../../plugins/i18n'

import {
  findCompanyByTenant,
  findCompanyBySlug,
  findCompanyById,
} from '../../domain/companies'
import CompanyAPI from '../../services/company-api.service'

const actions = {
  async getCompany({ commit, state }) {
    if (!state.tenant) {
      return
    }

    commit('mutate', { property: 'error', value: null })

    try {
      const company = await findCompanyByTenant(state.tenant).catch((error) => {
        commit('setError', error.message)
      })

      const {
        config,
        tenant,
        schemaName,
        relations,
        defaultLanguage = 'pt',
      } = company

      Metrics.set_group('Tenant', schemaName || tenant)
      // prettier-ignore
      Metrics.register({ 'Tenant': schemaName || tenant })

      commit('setManyStates', [
        { property: 'companyId', value: company.id },
        { property: 'company', value: company },
        { property: 'tenant', value: schemaName || tenant },
        { property: 'config', value: config || {} },
        { property: 'relations', value: relations },
        { property: 'error', value: null },
      ])

      const language = localStorage.getItem('lang') || defaultLanguage
      commit('setLocale', language)
      i18n.locale = language
    } catch ({ code }) {
      Logger.error(code)
      commit('setError', code)
    }
  },

  async getCompanyById({ dispatch }, companyId) {
    if (!Auth.currentUser?.uid) {
      await dispatch('signInAnonymous')
    }

    return findCompanyById(companyId)
  },

  async getCompanyBySlug({ state, commit, dispatch }, { slug, id }) {
    if (!slug) {
      return false
    }

    commit('setLoading', true)
    commit('setManyStates', [
      { property: 'reportId', value: null },
      { property: 'issueId', value: null },
      { property: 'error', value: null },
      { property: 'slug', value: null },
      { property: 'loading', value: true },
      { property: 'follow_ups', value: [] },
      { property: 'files', value: [] },
    ])

    try {
      if (!Auth.currentUser?.uid) {
        await dispatch('signInAnonymous')
      }

      let company = null

      if (id) {
        company = await findCompanyById(id).catch((error) => {
          commit('setError', error.message)
        })
      }

      if (!company?.id) {
        company = await findCompanyBySlug(slug).catch((error) => {
          commit('setError', error.message)
        })
      }

      if (!company?.id) {
        return
      }

      const {
        id: companyId,
        active,
        config,
        defaultLanguage = 'pt',
        relations,
        tenant,
        schemaName,
      } = company

      if (!active) {
        commit('setError', 'INACTIVE_COMPANY')
        return
      }

      await dispatch('assignCustomClaims', companyId)

      if (Auth.currentUser && !Auth.currentUser.isAnonymous) {
        if (state.currentUser?.tenant !== tenant) {
          await dispatch('getCompany')
          router.push('/')
          return
        }
      }

      commit('mutate', { property: 'companyId', value: companyId })

      commit('setManyStates', [
        { property: 'companyId', value: companyId },
        { property: 'company', value: company },
        { property: 'config', value: config || {} },
        { property: 'tenant', value: schemaName || tenant },
        { property: 'relations', value: relations },
        { property: 'error', value: null },
      ])

      const language = localStorage.getItem('lang') || defaultLanguage
      commit('setLocale', language)
      i18n.locale = language

      firebase.analytics().setUserProperties({ tenantID: tenant })
    } catch (err) {
      commit('mutate', { property: 'companyId', value: null })
      commit('mutate', { property: 'company', value: {} })
      commit('setError', err.code)
    } finally {
      commit('setLoading', false)
    }
  },

  async getCompaniesByTerm({ commit, dispatch }, term) {
    commit('mutate', { property: 'error', value: null })

    try {
      if (!Auth.currentUser?.uid) {
        await dispatch('signInAnonymous')
      }

      const { data: companies } = await CompanyAPI.search(term)

      return companies.map(({ id, name, slug, defaultLanguage }) => ({
        id,
        name,
        slug,
        lang: defaultLanguage,
      }))
    } catch ({ code }) {
      commit('setError', code)
    } finally {
      commit('setLoading', false)
    }

    return []
  },

  async getCompanyLogo({ state }, logoImgKey) {
    try {
      const response = await CompanyAPI.getCompanyLogo(
        state.companyId,
        logoImgKey,
      )

      return response?.url
    } catch ({ code }) {
      Logger.error(code)
    }

    return false
  },

  async getCompanyContents({ commit, state }) {
    try {
      const companyId = state.companyId

      if (!companyId) {
        return
      }

      const companyRef = DB.collection('companies').doc(companyId)

      const { docs } = await DB.collection('company_contents')
        .where('companyRef', '==', companyRef)
        .get()

      const contents = docs.map((doc) => {
        const { readers = [], ...rest } = doc.data()

        return {
          wasRead: readers.includes(state.employeeId),
          ...rest,
          id: doc.id,
        }
      })

      commit('mutate', {
        property: 'contents',
        value: contents,
      })
    } catch (err) {
      commit('setError', err)
    }
  },

  async updateCompanyContent({ dispatch, state, commit }, contentId) {
    if (!state?.employeeId) {
      return
    }

    await DB.collection('company_contents')
      .doc(contentId)
      .update({ readers: FieldValue.arrayUnion(state.employeeId) })
      .then(() => {
        Metrics.track('Content Read', {
          employeeId: state.employeeId,
        })
      })
      .catch(({ code }) => commit('setError', code))

    dispatch('getCompanyContents')
  },

  async getCompanyBranches({ state, commit }) {
    const branches = await CompanyAPI.getCompanyBranches(state.companyId)
    commit('mutate', {
      property: 'branches',
      value: branches,
    })
  },

  async getCompanyBranchesByKey({ state }, { branchKey }) {
    const response = await CompanyAPI.getCompanyBranchesByKey(
      state.companyId,
      branchKey,
    )
    return response
  },

  async getAvaiableCompanyDocuments() {
    return CompanyAPI.getDocuments()
  },

  async getDocument(_, documentId) {
    return CompanyAPI.getDocument(documentId)
  },

  async getDocumentFileUrl(_, { companyId, filekey }) {
    return CompanyAPI.getDocumentFileUrl(companyId, filekey)
  },

  async documentRead(_, documentId) {
    return CompanyAPI.documentRead(documentId)
  },
}

export default actions
