import { createInitialSmsState } from '.'
import { REPORT_FLOW_EXPERIMENT } from '@/globals'

// TODO Remove firebase rich objects from state
export const createInitialRootState = ({ currentUser }) => ({
  branches: [],
  company: {},
  companyId: null,
  contents: [],
  config: {},
  conversation: [],
  currentUser: currentUser || null,
  employeeId: null,
  error: null,
  followups: [],
  files: [],
  isAnonymousUser: true,
  issue: {},
  issueId: null,
  issueReplied: null,
  issues: [],
  lastIdx: 0,
  lastIssueReply: {},
  locale: localStorage.getItem('locale') || 'pt-BR',
  loading: false,
  notice: null,
  profile: {},
  relations: {},
  relationship: null,
  report: null,
  reportAreas: [],
  reportId: null,
  reportIds: [],
  reports: [],
  success: false,
  tenant: null,
  tenantId: null,
  totalReports: 0,
  totalPages: 0,
  username: '',
  unreadReports: false,
  unreadIssue: false,
  version: null,
  SMS: createInitialSmsState(),
  notificationChannel: null,
  experiments: {
    [REPORT_FLOW_EXPERIMENT]: ['original', 'new'],
  },
})
