import { mapActions, mapState, mapMutations, mapGetters } from 'vuex'
import { Auth } from '../../plugins/firebase'

import SIcon from '../../components/ui/s-icon'
import SButton from '../../components/ui/s-button'
import SContainer from '../../components/ui/s-container'
import STip from '../../components/ui/s-tip'
import iconeCofre from '../../assets/images/icone_cofre.svg'
import iconEmail from '../../assets/images/icon_email.svg'
import SLink from '../../components/ui/s-link'
import STitle from '../../components/ui/s-title'

export default {
  name: 'LoginForm',

  props: {
    loginCaption: {
      type: String,
      required: false,
      default: 'auth_access_page',
    },
    registerCaption: {
      type: String,
      default: '',
    },
  },

  components: {
    SIcon,
    SButton,
    SContainer,
    STip,
    SLink,
    STitle,
    Register: () => import('../AuthRegister'),
    CardMessage: () => import('../CardMessage'),
    SingleSignOnButton: () => import('@/components/SSO/SingleSignOnButton'),
  },

  created() {
    const queryParams = this.$router.currentRoute.query

    if (queryParams.err) {
      this.showRecovery = true
      this.setError(this.$t(queryParams.err))
    } else {
      this.showRecovery = Object.keys(queryParams).includes('firstAccess')
    }

    this.redirectToAfterSignOn = this.$route.params?.redirectTo || '/app'
  },

  destroyed() {
    this.showRecovery = false
  },

  data() {
    return {
      user: {
        email: '',
        password: '',
      },
      passwordFieldType: 'password',
      iconeCofre,
      showError: false,
      showSnackbar: false,
      showRecovery: false,
      isFirstAccess: false,
      snack: {},
      iconEmail,
      passwordWasSent: false,
      redirectToAfterSignOn: null,
    }
  },

  computed: {
    ...mapState(['currentUser', 'error', 'loading', 'notice', 'company']),
    ...mapGetters(['isFeatureEnabled']),

    isDisabled() {
      const invalidEmailAndPassword = !this.user.email && !this.user.password
      const emptyInputs = this.user.email === '' || this.user.password === ''
      return invalidEmailAndPassword || emptyInputs || this.loading
    },

    signedInAnonymously: () => Auth.currentUser && Auth.currentUser.isAnonymous,

    isSSOEnabled() {
      return this.isFeatureEnabled('singleSignOn')
    },

    providers() {
      const { config } = this.company || {}
      return config.sso?.providers || []
    },
  },

  methods: {
    ...mapActions(['login']),
    ...mapMutations(['setError']),

    async signIn() {
      this.popup('dark', 'auth_fetch_credentials')
      this.setError(null)

      if (this.signedInAnonymously) {
        await Auth.signOut()
      }

      await this.login(this.user)

      if (Auth.currentUser && !Auth.currentUser.isAnonymous) {
        this.$emit('handleSuccessfulAuthentication')
      }

      this.user.password = ''

      return false
    },

    togglePasswd() {
      this.passwordFieldType =
        this.passwordFieldType === 'password' ? 'text' : 'password'
    },

    popup(type, message) {
      this.showSnackbar = false
      this.snack = { type, message }
      this.showSnackbar = true
    },

    resetInstructionsWasSent() {
      this.$emit('handleResetPasswordRequest')

      this.passwordWasSent = true
    },
  },

  watch: {
    error() {
      if (this.error) this.popup('error', this.error)
    },

    notice() {
      if (this.notice && !this.company.slug) this.popup('success', this.notice)
    },
  },
}
