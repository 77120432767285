import { DB, Timestamp } from '@/plugins/firebase'
import Logger from '@/plugins/logger'

const actions = {
  async createDisclosure({ commit, state }, params) {
    const companyRef = DB.collection('companies').doc(state.companyId)

    const payload = {
      ...params,
      employeeId: state.employeeId,
      companyRef,
      createdAt: Timestamp.fromDate(new Date()),
    }

    const disclosure = await DB.collection('disclosures')
      .add(payload)
      .catch((err) => {
        Logger.error('Error creating disclosure', { err, payload })
        commit('setError', err.message)
      })

    return { id: disclosure.id, code: payload.code }
  },
}

export default actions
