import Vue from 'vue'
import Vuex from 'vuex'
import { Auth } from '../plugins/firebase'
import firebase from 'firebase/app'
import Logger from '../plugins/logger'
import Metrics from '../plugins/metrics'
import reportApiActions from './actions/report-api'
import firebaseServiceActions from './actions/firebase-service'
import companiesActions from './actions/companies'
import reportActions from './actions/report'
import followUpActions from './actions/followup'
import issueActions from './actions/issues'
import disclosureActions from './actions/disclosure'
import { AuthAPI } from '../services'
import { createInitialRootState } from './state-factories/root-state'
import { getters } from './getters'

import { TOKEN } from '../globals'

const debug = (error) => {
  if (error && process.env.NODE_ENV !== 'production') {
    console.trace(error) // eslint-disable-line no-console
  }
}

Vue.use(Vuex)

const store = new Vuex.Store({
  state: createInitialRootState({
    currentUser: Auth?.currentUser,
  }),

  getters,

  mutations: {
    setCompanyId: (state, companyId) => (state.companyId = companyId),
    setCurrentUser: (state, user) => (state.currentUser = user),
    setLocale: (state, language) => {
      state.locale = language === 'pt' ? 'pt-BR' : language
    },
    setLoading: (state, loading) => (state.loading = loading),
    setReport: (state, report) => (state.report = report),
    resetReport: (state) => (state.report = {}),
    setReports: (state, reports) => (state.reports = reports),
    setError: (state, error) => {
      state.error = error
      debug(error)
    },
    setNotice: (state, payload) => (state.notice = payload),
    setIssues: (state, payload) => state.issues.push(payload),
    setSMSIsModalDismissed: (state, isDismissed) =>
      (state.SMS.isModalDismissed = isDismissed),
    addSMSSendingId: (state, sendId) => state.SMS.sendingIds.push(sendId),
    setSMSLastSendingStatus: (state, smsSendingStatus) =>
      (state.SMS.lastSendingStatus = smsSendingStatus),

    mutate: (state, payload) => (state[payload.property] = payload.value),
    setManyStates: (state, payload) => {
      payload.forEach((item) => {
        state[item.property] = item.value
      })
    },
    reset: (state) => {
      state.error = null
      state.notice = null
      state.loading = false
    },
    setNotificationChannel: (state, payload) =>
      (state.notificationChannel = payload),
  },

  actions: {
    async login({ commit }, { email, password }) {
      commit('setLoading', true)
      commit('setError', null)
      localStorage.removeItem(TOKEN)

      try {
        await firebase
          .auth()
          .setPersistence(firebase.auth.Auth.Persistence.SESSION)

        const { user } = await Auth.signInWithEmailAndPassword(email, password)

        if (user?.uid) {
          return
        }

        const { claims, token } = await user.getIdTokenResult(true)
        localStorage.setItem(TOKEN, token)

        commit('setCurrentUser', { ...user, ...claims })

        commit('setManyStates', [
          { property: 'tenant', value: claims.schemaName || claims.tenant },
          { property: 'notice', value: null },
          { property: 'error', value: null },
          { property: 'loading', value: false },
        ])
      } catch (error) {
        commit('setError', error.code)
      } finally {
        commit('setLoading', false)
      }
    },

    async signInWithSAML({ commit, dispatch }, provider) {
      try {
        localStorage.removeItem(TOKEN)

        const samlProvider = new firebase.auth.SAMLAuthProvider(provider)

        const { additionalUserInfo, user } = await Auth.signInWithPopup(
          samlProvider,
        )

        const { claims, token } = await user.getIdTokenResult(true)
        localStorage.setItem(TOKEN, token)

        commit('setCurrentUser', { ...user, ...claims })

        const { tenant, schemaName } = claims

        commit('setManyStates', [
          { property: 'tenant', value: schemaName || tenant },
          { property: 'notice', value: null },
          { property: 'error', value: null },
          { property: 'loading', value: false },
        ])

        await dispatch('upsertEmployee', { additionalUserInfo, user })

        return user
      } catch (error) {
        commit('setError', error.message)
        throw error
      }
    },

    async upsertEmployee({ state }, result) {
      const { additionalUserInfo, user } = result
      if (!user?.uid) {
        return
      }

      const { token } = await Auth.currentUser.getIdTokenResult(true)
      localStorage.setItem(TOKEN, token)

      const { profile, providerId } = additionalUserInfo

      const userInfo = {
        uid: user.uid,
        name: [profile?.firstName, profile?.lastName].join(' '),
        ...profile,
        providerId,
        companyId: state.companyId,
      }

      return AuthAPI.upsertUser(userInfo)
    },

    async assignCustomClaims({ commit, dispatch }, companyId) {
      if (!companyId) {
        return
      }

      commit('setLoading', true)

      try {
        const response = await AuthAPI.setCustomClaims({ companyId })

        if (response?.status === 200) {
          await dispatch('getUserToken')
        }

        return response?.status
      } catch ({ message, response }) {
        const { status } = response || {}

        if (status && status === 403) {
          await dispatch('logout')
          await dispatch('signInAnonymous', companyId)
        }

        Logger.info('Error assigning user claims')
      } finally {
        commit('setLoading', false)
      }
    },

    async signInAnonymous({ dispatch, state }, companyId) {
      if (Auth.currentUser && !Auth.currentUser.isAnonymous) {
        return
      }

      const currentCompanyId = companyId || state.companyId

      await Auth.signInAnonymously()

      await dispatch('assignCustomClaims', currentCompanyId)
    },

    async getUserToken({ commit, dispatch, state }) {
      if (!Auth.currentUser) {
        return
      }

      try {
        const user = await Auth.currentUser.getIdTokenResult(true)

        const { claims, tenantId, token } = user
        localStorage.setItem(TOKEN, token)

        commit('setCurrentUser', user)

        const {
          companyId,
          email,
          employeeId,
          tenant,
          schemaName,
          user_id,
          name,
        } = claims

        commit('setManyStates', [
          { property: 'tenant', value: schemaName || tenant },
          { property: 'tenantId', value: tenantId },
          { property: 'employeeId', value: employeeId },
          { property: 'username', value: email },
          { property: 'isAnonymousUser', value: Auth.currentUser?.isAnonymous },
          { property: 'currentUser', value: claims },
        ])

        await dispatch('getCompany')

        const userIdentity = {
          id: employeeId || user_id,
          companyId: state.companyId || companyId || tenantId,
          schemaName: schemaName || tenant,
          name,
          email,
          uid: user_id,
          isAnonymousUser: Auth.currentUser?.isAnonymous,
        }

        Metrics.identify(userIdentity.uid)
        Metrics.people.set({
          // prettier-ignore
          '$name': userIdentity.name,
          // prettier-ignore
          '$email': userIdentity.email,
          'Company ID': userIdentity.companyId,
          'Employee ID': employeeId,
          // prettier-ignore
          'Tenant': tenant,
        })
      } catch (err) {
        localStorage.clear()
        Logger.error(err.message, err)
        commit('setError', err.code)
      }
    },

    /**
     * Send email with reset password instructions
     */
    async sendResetPasswordInstructions({ commit }, email) {
      await AuthAPI.resetPassword({ email })
        .then(() => {
          commit('mutate', {
            property: 'notice',
            value: 'Enviamos um email com instruções de acesso.',
          })
          commit('mutate', { property: 'error', value: null })
          commit('mutate', { property: 'loading', value: false })
        })
        .catch((error) => {
          localStorage.clear()
          commit('mutate', { property: 'notice', value: null })
          commit('mutate', { property: 'error', value: error.message })
          commit('mutate', { property: 'loading', value: false })
        })
    },

    async passwordSetup({ commit, dispatch }, payload) {
      commit('setManyStates', [
        { property: 'loading', value: true },
        { property: 'notice', value: null },
        { property: 'error', value: null },
        { property: 'success', value: false },
      ])

      await Auth.confirmPasswordReset(payload.code, payload.secret)
        .then(async () => {
          await Auth.signInWithEmailAndPassword(payload.email, payload.secret)
            .then(async ({ user }) => {
              await dispatch('getUserToken', user)

              commit('setManyStates', [
                { property: 'notice', value: 'PASSWORD_RESET' },
                { property: 'error', value: null },
                { property: 'success', value: true },
              ])
            })
            .catch((error) => commit('setError', error.code))
        })
        .catch((error) => commit('setError', error.code))
        .finally(() => {
          localStorage.clear()
          commit('setLoading', false)
        })
    },

    updateCredentials({ state, commit }, payload) {
      const credentials = firebase.auth.EmailAuthProvider.credential(
        state.username,
        payload.old_password,
      )

      Auth.currentUser
        .reauthenticateWithCredential(credentials)
        .then(() => {
          Auth.currentUser
            .updatePassword(payload.password)
            .then(() => {
              commit('setError', null)
              commit('mutate', {
                property: 'notice',
                value: 'PASSWORD_RESET_INSTRUCTIONS_SENT',
              })
            })
            .catch(() => commit('setError', 'PASSWORD_INVALID'))
        })
        .catch(() => commit('setError', 'PASSWORD_NOT_MATCH'))
    },

    async logout({ commit }) {
      await Auth.signOut()
        .then(() => commit('setCurrentUser', null))
        .catch((error) => commit('setError', error))

      localStorage.clear()
      sessionStorage.clear()
    },

    async getResourceByCode({ commit, dispatch }, payload) {
      commit('setLoading', true)
      commit('mutate', { property: 'reportId', value: null })
      commit('mutate', { property: 'issueId', value: null })
      commit('mutate', { property: 'success', value: false })

      await dispatch('getReportByProtocol', payload)
    },

    getExperimentVariant({ state }, experimentName) {
      const experiment = localStorage.getItem(experimentName)

      if (experiment) {
        return experiment
      }

      const experimentData = state.experiments[experimentName]

      const variant =
        experimentData[Math.floor(Math.random() * experimentData.length)]

      localStorage.setItem(experimentName, variant)

      return variant
    },

    ...firebaseServiceActions,
    ...reportApiActions,
    ...companiesActions,
    ...reportActions,
    ...followUpActions,
    ...issueActions,
    ...disclosureActions,
  },
})

export default store
