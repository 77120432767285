import pick from 'lodash/pick'
import map from 'lodash/map'
import last from 'lodash/last'
import { Auth, DB, Timestamp, FieldValue } from '../../plugins/firebase'
import Metrics from '../../plugins/metrics'

const PER_PAGE = 30

const actions = {
  async createIssue({ commit, state }, payload) {
    if (!state.companyId) {
      commit('setError', 'UNAVAILABLE')
      return
    }

    const companyRef = DB.doc(`companies/${state.companyId}`)

    payload.companyRef = companyRef
    payload.status = 'NEW'
    payload.replied = false

    if (!payload.anonymous) {
      if (state.employeeId) {
        payload.employeeId = state.employeeId
      } else {
        payload.anonymous = true

        if (Auth.currentUser.isAnonymous) {
          payload.auid = Auth.currentUser.uid
        }
      }
    }

    payload.sentAt = Timestamp.fromDate(new Date())

    const snap = await DB.collection('issues')
      .add(payload)
      .catch((err) => commit('setError', err))

    commit('mutate', { property: 'issueId', value: snap.id })

    if (!payload.anonymous) {
      commit('setIssues', { ...payload, id: snap.id })
    }

    Metrics.track('Issue Submitted', {
      code: payload.protocol,
      hasFiles: !!payload.files.length,
    })

    commit('mutate', { property: 'notice', value: 'ISSUE_SENT' })

    return {
      ...payload,
      id: snap.id,
    }
  },

  async getIssue({ commit }, issueId) {
    const snap = await DB.collection('issues')
      .doc(issueId)
      .get()
      .catch((err) => commit('setError', err.message))

    if (!snap.exists) {
      return null
    }

    const issue = { id: snap.id, ...snap.data() }

    commit('mutate', { property: 'issueId', value: snap.id })
    commit('mutate', { property: 'issue', value: issue })
    commit('mutate', { property: 'issueReplied', value: issue.replied })

    return issue
  },

  async updateIssue({ commit, state }, payload) {
    const params = pick(payload, ['completed', 'status', 'unread', 'replied'])
    await DB.collection('issues')
      .doc(state.issueId)
      .update(params)
      .catch((err) => commit('setError', err.code))
      .finally(() => commit('setLoading', false))

    return false
  },

  async sendIssueFollowUpMessage({ state, commit, dispatch }, data) {
    const payload = {
      ...data,
      issueId: state.issueId,
      source: 'USER',
      sentAt: Timestamp.fromDate(new Date()),
    }

    await DB.collection('issues_follow_ups')
      .add(payload)
      .catch((err) => commit('setError', err.code))

    dispatch('updateIssue', {
      unread: FieldValue.increment(1),
      replied: false,
    })

    return payload
  },

  async getIssueFollowUpMessages({ commit }, issueId) {
    commit('mutate', { property: 'conversation', value: [] })

    const documents = await DB.collection('issues_follow_ups')
      .where('issueId', '==', issueId)
      .orderBy('sentAt', 'asc')
      .get()

    return documents.docs.map((doc) => {
      return {
        id: doc.id,
        ...doc.data(),
      }
    })
  },

  async getIssueByProtocol({ commit }, payload) {
    commit('mutate', { property: 'success', value: false })
    commit('mutate', { property: 'issueId', value: null })

    const snap = await DB.collection('issues')
      .where('anonymous', '==', true)
      .where('protocol', '==', payload.protocol)
      .limit(1)
      .get()
      .catch((err) => commit('setError', err.code))
      .finally(() => commit('setLoading', false))

    if (snap.empty) {
      commit('setManyStates', [
        { property: 'loading', value: false },
        { property: 'error', value: 'resource-not-found' },
      ])
      return false
    }

    const [document] = snap.docs
    const { companyRef, replied } = document.data()
    commit('setManyStates', [
      { property: 'companyId', value: companyRef.id },
      { property: 'issueId', value: document.id },
      { property: 'error', value: null },
      { property: 'loading', value: false },
      { property: 'success', value: true },
      { property: 'issueReplied', value: replied },
    ])

    return {
      ...document.data(),
      id: document.id,
    }
  },

  async preFetchIssues({ state, dispatch }) {
    await dispatch('getUserToken')

    return DB.collection('issues')
      .where('anonymous', '==', false)
      .where('employeeId', '==', state.employeeId)
      .orderBy('sentAt', 'desc')
  },

  async getIssues({ commit, dispatch }) {
    commit('setLoading', true)
    const query = await dispatch('preFetchIssues')

    query
      .limit(PER_PAGE)
      .get()
      .then((snap) => dispatch('loadIssues', snap))
      .catch((err) => commit('setError', err.code))
      .finally(() => commit('setLoading', false))
  },

  loadIssues({ commit }, snap) {
    commit('mutate', { property: 'firstIssue', value: snap.docs[0] })
    commit('mutate', { property: 'lastIssue', value: last(snap.docs) })

    if (!snap.empty) {
      const issues = map(snap.docs, (doc) => {
        return { ...doc.data(), id: doc.id }
      })
      const unread = issues.some((record) => record.status === 'ANSWERED')
      commit('mutate', { property: 'issues', value: issues })
      commit('mutate', { property: 'unreadIssue', value: unread })
    }
    commit('setLoading', false)
  },

  async paginateIssues({ commit, dispatch, state }, direction) {
    commit('setLoading', true)
    let query = await dispatch('preFetchIssues')

    if (direction == 'forward') {
      query = query.startAfter(state.lastIssue).limit(PER_PAGE)
    } else {
      query = query.endBefore(state.firstIssue).limitToLast(PER_PAGE)
    }

    query
      .get()
      .then((snap) => dispatch('loadIssues', snap))
      .catch((err) => commit('setError', err.message))
      .finally(() => commit('setLoading', false))
  },
}

export default actions
