import { trackEvent } from '../../plugins/firebase'

import NotificationGateway from '../../gateways/notification'
import { smsSendingStatuses } from '../../views/Reports/enums'
import i18n from '../../plugins/i18n'
import Logger from '../../plugins/logger'
import Metrics from '../../plugins/metrics'

const actions = {
  handleSubmitReport(
    { commit },
    { source, anonymous, channel, archive, match },
  ) {
    if (!archive) {
      if (anonymous) {
        trackEvent('app_report_sent_anon', { app_name: 'colaborador' })
      } else {
        trackEvent('app_report_sent_iden', { app_name: 'colaborador' })
      }

      trackEvent('app_report_sent', {
        app_name: 'colaborador',
        is_anonymous: anonymous,
      })

      const mode = anonymous ? 'ANONYMOUS' : 'IDENTIFIED'
      Metrics.track('Report Submitted', {
        mode: match ? 'CONNECT' : mode,
        source,
        channel,
        anonymous,
      })
    } else {
      Metrics.track('Report Archived', { source, channel, anonymous })
      trackEvent('app_report_saved', { app_name: 'colaborador' })
    }

    commit('setManyStates', [
      { property: 'success', value: true },
      { property: 'loading', value: false },
    ])
  },

  async sendSMS({ commit, state, dispatch }, sanitizedPhoneNumber) {
    const { report, company } = state
    const { slug, slugId } = company

    commit('setLoading', true)

    try {
      const sendId = await NotificationGateway().sendSMS(
        sanitizedPhoneNumber,
        `SafeSpace: ${i18n.t('SMS_SENT_CODE_MESSAGE', {
          reportProtocol: report.code,
          mySpace: `https://app.safe.space/company/${slug}/${slugId}`,
        })}`,
      )

      const { source, channel, anonymous } = report
      Metrics.track('SMS Code Requested', { source, channel, anonymous })

      dispatch('updateSMSSendingWithSuccess', sendId)
    } catch (err) {
      dispatch('updateSMSSendingWithFailure', err)
    } finally {
      commit('setLoading', false)
    }
  },

  updateSMSSendingWithSuccess({ commit }, sendId) {
    commit('addSMSSendingId', sendId)
    commit('setSMSLastSendingStatus', smsSendingStatuses.succeeded)
    commit('setError', null)
    trackEvent('sms_report_sent')
  },

  updateSMSSendingWithFailure({ commit }, err) {
    Logger.error(err)

    commit('setSMSLastSendingStatus', smsSendingStatuses.failed)
    commit('setError', i18n.t('SMS_SENDING_FAILED_TOAST'))
  },

  dismissSMSModal({ commit }) {
    commit('setSMSIsModalDismissed', true)
  },

  openSMSModal({ commit }) {
    commit('setSMSIsModalDismissed', false)
  },

  resetSMSModalFlow({ commit }) {
    commit('setSMSIsModalDismissed', false)
    commit('setSMSLastSendingStatus', smsSendingStatuses.empty)
    commit('setError', null)
  },
}

export default actions
