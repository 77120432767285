<template>
  <div :class="['agreements', align]">
    <label class="checkbox">
      <input v-model="agree" type="checkbox" @change="$emit('input', agree)" />
      <span>
        {{ $t('AGREEMENTS') }}
        <router-link :to="{ name: 'terms' }">
          {{ $t('TERMS_OF_USE') }}
        </router-link>
        {{ $t('AND') }}
        <router-link :to="{ name: 'privacy' }" class="lowercase">
          {{ $t('PRIVACY_POLICY') }}.
        </router-link>
      </span>
    </label>
  </div>
</template>

<script>
export default {
  name: 'Agreements',

  props: {
    align: {
      type: String,
      default: 'center',
    },
  },

  data() {
    return {
      agree: false,
    }
  },
}
</script>

<style scoped>
.agreements {
  padding: 0 15px;
}
.agreements.center {
  text-align: center;
}
.agreements.left {
  text-align: left;
}

.agreements .lowercase {
  text-transform: lowercase;
}
</style>
