import { defaultClientFactory } from './default-client-factory'

const defaultClient = defaultClientFactory()

const sendSMS = (client) => async (phoneNumber, body) => {
  const sendResult = await client.sendSMS({
    body,
    to: phoneNumber,
  })

  return sendResult.sid
}

export const NotificationGateway = (
  { client: customClient } = { client: defaultClient },
) => ({
  sendSMS: sendSMS(customClient),
})

export default NotificationGateway
