<template>
  <div id="app" :class="theme">
    <Header :theme="theme" />
    <div class="app-container">
      <router-view />
    </div>

    <md-snackbar
      class="bg-dark"
      md-position="center"
      :md-duration="4000"
      :md-active.sync="isOffline"
    >
      <span>Você está offline</span>
    </md-snackbar>

    <Footer :theme="theme" />
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

import Header from './components/Header'
import Footer from './components/Footer'

export default {
  name: 'App',

  components: {
    Header,
    Footer,
  },

  data: () => ({
    isOffline: false,
    metas: {},
    theme: 'sea',
  }),

  computed: mapState(['currentUser', 'companyId']),

  mounted() {
    window.addEventListener('offline', () => {
      this.isOffline = true
    })
    window.addEventListener('online', () => {
      this.isOffline = false
    })
    this.$i18n.locale = localStorage.locale
  },

  updated() {
    this.metas = this.$route.matched.reduce((record) => record?.meta, [])
    this.theme = this.metas?.theme || 'sea'
  },

  methods: mapMutations(['setError']),
}
</script>

<style lang="css">
@import 'assets/styles/font-face.css';
@import 'assets/styles/theme.css';

@import '~vue-material/dist/vue-material.min.css';
@import '~vue-cool-lightbox/dist/vue-cool-lightbox.min.css';

#app {
  color: var(--color-grey-default);
  font-family: 'FieldWork', Avenir, Helvetica, Arial, sans-serif;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.app-container {
  min-height: var(--default-height);
}

@media (min-width: 767px) {
  .app-container {
    min-height: var(--default-height-desktop);
  }
}

body {
  margin: 0;
  padding: 0;
  width: 100%;
  font-size: 14px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

p {
  font-size: 16px;
  font-weight: 300;
}

a,
a:visited {
  color: var(--color-blue-default);
}

.text-center {
  text-align: center;
}

a,
button {
  font-family: 'FieldWork';
}

ul {
  list-style: none;
}
ul.inline {
  display: inline;
}

input {
  border: 2px solid var(--color-blue-default);
  border-radius: 25px;
  box-sizing: border-box;
  color: var(--color-grey-default);
  display: block;
  font-family: 'FieldWork';
  font-size: 14px;
  padding: 15px 20px;
  width: 100%;
  outline: none;
}

input.dark,
input.rock {
  border-color: var(--color-grey-default);
}

input + input {
  margin: 35px 0;
}

input:last-of-type {
  margin-bottom: 0;
}

input[type='radio'] {
  display: inline;
  width: auto;
}

input[disabled] {
  border-color: var(--color-grey-lighter);
  color: var(--color-grey-dark);
}

input::placeholder {
  color: var(--color-grey-lighter);
}

textarea {
  color: var(--color-grey-default);
  font-family: 'FieldWork';
  font-size: 14px;
}

textarea:focus,
textarea:active {
  outline: 0;
}

textarea::placeholder {
  color: var(--color-grey-lighter);
}

.tip {
  color: var(--color-blue-default);
  display: block;
  font-size: 14px;
  margin-top: 15px;
  text-align: center;
}

.tip a {
  color: var(--color-blue-default);
  font-weight: 700;
  position: relative;
  text-decoration: none;
}

.tip a::after {
  background-color: var(--color-blue-default);
  bottom: -1px;
  content: '';
  left: 0;
  height: 1px;
  position: absolute;
  width: 100%;
}

.grid-50 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 5%;
}

.color-blue {
  color: var(--color-blue-default);
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.slide-enter-active {
  transition-duration: 0.3s;
  transition-timing-function: ease-in;
}

.slide-leave-active {
  transition-duration: 0.3s;
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}

.slide-enter-to,
.slide-leave {
  overflow: hidden;
}

.slide-enter,
.slide-leave-to {
  overflow: hidden;
  max-height: 0;
}

/* Enter and leave animations can use different */
/* durations and timing functions. */
.slide-fade-enter-active {
  transition: all 0.3s ease;
}

.slide-fade-enter,
.slide-fade-leave-to {
  /* .slide-fade-leave-active below version 2.1.8 */
  transform: translateX(10px);
  opacity: 0;
}

/* hidden recapcha */
.grecaptcha-badge {
  visibility: hidden;
}
</style>
