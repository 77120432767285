import { datadogLogs } from '@datadog/browser-logs'

const {
  VUE_APP_DATADOG_ENV,
  VUE_APP_DATADOG_ENABLED_LOG,
  VUE_APP_DATADOG_CLIENT_TOKEN,
} = process.env

class DatadogLogger {
  isLogEnabled
  logger
  ddInstance
  constructor(datadogInstance, enabled = true) {
    this.ddInstance = datadogInstance
    this.logger = this.ddInstance.logger
    this.isLogEnabled = enabled

    if (!this.isLogEnabled) {
      this.logger = console
    }
  }

  debug(message, data) {
    this.logger?.debug(message, { data })
  }

  info(message, data) {
    this.logger?.info(message, { data })
  }

  warn(message, data) {
    this.logger?.warn(message, { data })
  }

  error(message, context, component) {
    this.logger?.error(message, { error: context }, component)
  }
}

datadogLogs.init({
  clientToken: VUE_APP_DATADOG_CLIENT_TOKEN,
  site: 'datadoghq.com',
  forwardErrorsToLogs: true,
  sessionSampleRate: 100,
  service: `collab-app-${VUE_APP_DATADOG_ENV}`,
})

datadogLogs.setGlobalContextProperty('env', process.env.NODE_ENV)

const enabledLog = VUE_APP_DATADOG_ENABLED_LOG === 'true' || false

const logger = new DatadogLogger(datadogLogs, enabledLog)

export default logger
