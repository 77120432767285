import Vue from 'vue'
import VueApollo from 'vue-apollo'
import { ApolloClient } from 'apollo-client'
import { ApolloLink, from } from 'apollo-link'
import { createHttpLink } from 'apollo-link-http'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { onError } from 'apollo-link-error'

import { AUTH_TENANT_ID, TOKEN } from '../globals'
import Logger from './logger'
import { getDDSessionId } from '../services/session-service'

const httpLink = createHttpLink({
  uri: process.env.VUE_APP_REPORT_API,
})

const authMiddleware = new ApolloLink((operation, forward) => {
  const token = localStorage.getItem(TOKEN)
  const tenantId = localStorage.getItem(AUTH_TENANT_ID)

  operation.setContext(({ headers }) => {
    const sessionId = getDDSessionId()
    const customHeaders = {
      Authorization: `Bearer ${token}`,
      'x-dd-session-id': sessionId,
      ...headers,
    }

    if (tenantId) {
      customHeaders['X-Tenant-Id'] = tenantId
    }

    return { headers: customHeaders }
  })

  return forward(operation)
})

const loggerMiddleware = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) => {
      Logger.error('[GraphQL error]', { message, locations, path })
    })
  }

  if (networkError) {
    Logger.error(`[Network error]: ${networkError}`)
  }
})

const cache = new InMemoryCache()

export const apolloClient = new ApolloClient({
  link: from([authMiddleware, loggerMiddleware, httpLink]),
  cache,
  credentials: 'include',
})

const apolloProvider = new VueApollo({
  defaultClient: apolloClient,
})

Vue.use(VueApollo)

export default apolloProvider
