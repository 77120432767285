import Vue from 'vue'
import Router from 'vue-router'
import Firebase from './firebase'
import store from '../store'

// Auth
import welcomePage from '../views/Welcome'
import loginPage from '../views/Auth/Login'
import passwordPage from '../views/Auth/PasswordRecovery'

// Report
const mySpaceView = () => import('../views/MySpace')
const myReportsPage = () => import('../views/MySpace/MyReports')
const myArchivePage = () => import('../views/MySpace/MyArchive')
const myMatchReportsPage = () => import('../views/MySpace/MyMatchReports')
const myIssuesPage = () => import('../views/MySpace/MyIssues')

const homePage = () => import('../views/Home')
// Company
import companyView from '../views/Company/CompanyView'
const companySplash = () => import('../views/Company/CompanySplash')
const companyRoot = () => import('../views/Company/CompanyRoot')
const companyContainer = () => import('../views/Company/CompanyContainer')
const companyReport = () => import('../views/Company/Report')

const reportNew = () => import('../views/Reports/ReportNew')
const followUpPage = () => import('../views/FollowUp')

const reportPending = () => import('../views/Reports/ReportPending')
const reportSuccess = () => import('../views/Reports/ReportSentSuccess')
const reportSubscribe = () => import('../views/Reports/ReportSubscribe')

// Disclosure
const disclosureHome = () => import('../views/Disclosure/DisclosureHome')
const disclosureSuccess = () => import('../views/Disclosure/DisclosureSuccess')
const disclosureOutsideRelationship = () =>
  import('../views/Disclosure/DisclosureOutsideRelationship')
const disclosureGifts = () => import('../views/Disclosure/DisclosureGifts')
const disclosureInsideRelationship = () =>
  import('../views/Disclosure/DisclosureInsideRelationship')
const disclosureOtherPotencialConflitInterest = () =>
  import('../views/Disclosure/DisclosureOtherPotencialConflitInterest')

// Issues
import issuesView from '../views/OpenChannels/Issues'
const issuesHome = () => import('../views/OpenChannels/Issues/Home')
const issuesForm = () => import('../views/OpenChannels/Issues/Form')
const issuesStatus = () => import('../views/OpenChannels/Issues/Status')
const issuesConversation = () => import('../views/Issues/Conversation')
const issuesNew = () => import('../views/Issues/IssuesNew')

//Disclosure
const disclosureOutsideInterest = () =>
  import('../views/Disclosure/DisclosureOutsideInterest')

// Static
const faqPage = () => import('../views/Faq')
const contentPage = () => import('../views/Content')
const termsPage = () => import('../views/Terms')
const privacyPage = () => import('../views/Privacy')
// Misc
const settingsPage = () => import('../views/Settings')
import Error from '../components/Error'
import Maintenance from '../components/Maintenance.vue'

// Policies
const policiesHome = () => import('../views/Policies/PoliciesHome')
const policyDetails = () => import('../views/Policies/PolicyDetails')

Vue.use(Router)

export const routes = {
  mode: 'history',
  routes: [
    { path: '/', name: 'welcome', component: welcomePage },
    {
      path: '/auth',
      name: 'auth',
      component: loginPage,
      alias: ['/login', '/register'],
    },
    { path: '/setup', name: 'setup', component: passwordPage },
    {
      path: '/home',
      name: 'home',
      component: homePage,
      meta: { authenticated: true },
    },
    {
      path: '/company/:slug',
      name: 'company-splash',
      component: companySplash,
    }, // TODO: remove me
    {
      path: '/company/:slug/:slugId',
      component: companyView,
      children: [
        { path: '/', name: 'company', component: companyRoot },
        {
          path: 'auth',
          name: 'company-auth',
          component: loginPage,
        },
        {
          path: 'home',
          name: 'company-home',
          component: companyContainer,
        },
        {
          path: 'report',
          name: 'company-report',
          component: companyReport,
        },
        {
          path: 'report/sent',
          name: 'company-report-success',
          component: reportSuccess,
        },
        {
          path: 'report/subscribe',
          name: 'company-report-subscribe',
          component: reportSubscribe,
        },
        {
          path: 'disclosure',
          name: 'company-disclosure',
          component: disclosureHome,
          meta: { authenticated: true },
        },
        {
          path: 'disclosure/outside-interest',
          name: 'outside-interest',
          component: disclosureOutsideInterest,
        },
        {
          path: 'disclosure/sent',
          name: 'company-disclosure-success',
          component: disclosureSuccess,
        },
        {
          path: 'disclosure/gifts',
          name: 'gifts',
          component: disclosureGifts,
        },
        {
          path: 'disclosure/outside-relationship',
          name: 'outside-relationship',
          component: disclosureOutsideRelationship,
        },
        {
          path: 'disclosure/inside-relationship',
          name: 'inside-relationship',
          component: disclosureInsideRelationship,
        },
        {
          path: 'disclosure/other-potencial-conflit-interest',
          name: 'other-potencial-conflit-interest',
          component: disclosureOtherPotencialConflitInterest,
        },
        {
          path: 'policies',
          name: 'company-policies',
          component: policiesHome,
          meta: { authenticated: true },
        },
        {
          path: 'policies/:policyId',
          name: 'policy-details',
          component: policyDetails,
          meta: { authenticated: true },
        },
      ],
    },
    {
      path: '/app',
      component: mySpaceView,
      meta: { authenticated: true },
      children: [
        { path: '/', name: 'my-space', component: myReportsPage },
        {
          path: 'reports/archived',
          name: 'my-archived',
          component: myArchivePage,
        },
        {
          path: 'reports',
          name: 'my-reports',
          component: myReportsPage,
        },
        {
          path: 'match-reports',
          name: 'my-match-reports',
          component: myMatchReportsPage,
        },
        { path: 'issues', name: 'my-issues', component: myIssuesPage },
      ],
    },
    {
      path: '/report/new',
      name: 'new-report',
      component: reportNew,
      meta: { authenticated: true },
    },
    {
      path: '/report/success',
      name: 'report-success',
      component: reportSuccess,
      meta: { authenticated: true },
      props: ({ query, params }) => ({
        ...query,
        ...params,
      }),
    },
    {
      path: '/report/:token/follow-up',
      name: 'follow-up',
      component: followUpPage,
      meta: { authenticated: true },
      props: ({ query, params }) => ({
        ...query,
        ...params,
      }),
    },
    {
      path: '/report/:token/pending',
      name: 'report-pending',
      component: reportPending,
      meta: { authenticated: true },
      props: ({ query, params }) => ({
        ...query,
        ...params,
      }),
    },
    {
      path: '/issues/new',
      name: 'new-issue',
      component: issuesNew,
      meta: { authenticated: true, theme: 'eco' },
    },
    {
      path: '/issues/:code',
      name: 'conversation',
      component: issuesConversation,
      meta: { authenticated: true, theme: 'eco' },
    },

    { path: '/fazer-relato/:slug', redirect: { name: 'company' } },

    { path: '/faq', name: 'faq', component: faqPage },
    { path: '/content', name: 'content', component: contentPage },
    { path: '/terms', name: 'terms', component: termsPage },
    { path: '/privacy', name: 'privacy', component: privacyPage },

    {
      path: '/settings',
      name: 'settings',
      component: settingsPage,
      meta: { authenticated: true },
    },
    {
      path: '/r/:token/follow-up',
      name: 'report-follow-up',
      component: followUpPage,
      props: ({ query, params }) => ({
        ...query,
        ...params,
      }),
    },
    {
      path: '/i/:token/follow-up',
      name: 'issue-follow-up',
      component: issuesConversation,
    },
    { path: '/perguntas/:slug', component: issuesHome }, // TODO: remove me
    {
      path: '/perguntas/:slug/:slugId',
      component: issuesView,
      meta: { anonymous: true, theme: 'eco' },
      children: [
        { path: '/', name: 'issues', component: issuesHome },
        { path: 'enviar', name: 'issues-form', component: issuesForm },
        { path: ':code', name: 'issue-status', component: issuesStatus },
        {
          path: ':code/chat',
          name: 'issue-chat',
          component: issuesConversation,
        },
      ],
    },
    { path: '/maintenance', component: Maintenance },
    {
      path: '*',
      component: Error,
      meta: {
        code: 404,
      },
    },
  ],
}

const router = new Router(routes)

router.beforeEach((to, from, next) => {
  const isAuthRequired = to.matched.some((route) => route.meta.authenticated)

  Firebase.getCurrentUser().then((currentUser) => {
    if (isAuthRequired && !currentUser) {
      let redirectToRouteName = 'auth'
      const { slug, slugId } = from.params

      if (slug && slugId) {
        redirectToRouteName = 'company-auth'
      }

      next({
        name: redirectToRouteName,
        params: {
          redirectTo: to.fullPath,
        },
      })
    } else {
      to.params['currentUserId'] = currentUser?.uid
      store.dispatch('getUserToken').then(() => next())
    }
  })
})

export default router
