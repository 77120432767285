import axios from 'axios'
import { TOKEN } from '../globals'
import { Auth } from './firebase'
import Logger from './logger'
import { getDDSessionId } from '../services/session-service'

const getUserToken = async () => {
  if (!Auth.currentUser?.uid) {
    return
  }

  try {
    const token = await Auth.currentUser.getIdToken()

    localStorage.setItem(TOKEN, token)

    return token
  } catch (err) {
    Logger.error(err)
  }
}

const headers = {
  'Access-Control-Allow-Origin': '*',
  'Content-Type': 'application/json',
}

const authInterceptor = async (config) => {
  const token = await getUserToken()
  const sessionId = getDDSessionId()
  config.headers['Authorization'] = `Bearer ${token}`
  config.headers['x-dd-session-id'] = sessionId
  return config
}

const bodyDataInterceptor = (response) => {
  response.data = response.data.data

  return response
}

const errorHandler = (error) => {
  localStorage.removeItem(TOKEN)
  return Promise.reject(error)
}

const authAPI = axios.create({
  baseURL: process.env.VUE_APP_AUTH_API,
  headers,
  withCredentials: true,
})

const firebaseServiceAPI = axios.create({
  baseURL: process.env.VUE_APP_SERVELESS_API,
  headers,
  withCredentials: true,
})

const companyAPI = axios.create({
  baseURL: process.env.VUE_APP_COMPANY_API,
  headers,
  withCredentials: true,
})

firebaseServiceAPI.interceptors.request.use(authInterceptor, errorHandler)
authAPI.interceptors.request.use(authInterceptor, errorHandler)
companyAPI.interceptors.request.use(authInterceptor, errorHandler)
companyAPI.interceptors.response.use(bodyDataInterceptor)

export { authAPI, firebaseServiceAPI, companyAPI }
