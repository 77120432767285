import Vue from 'vue'
import App from './App.vue'
import VueMaterial from 'vue-material'
import VueScrollTo from 'vue-scrollto'
require('es6-promise').polyfill()
import Vue2TouchEvents from 'vue2-touch-events'

import store from './store'
import i18n from './plugins/i18n'
import router from './plugins/router'
import apolloProvider from './plugins/apolloProvider'

const { NODE_ENV } = process.env
const isProduction = NODE_ENV === 'production'

const moment = require('moment')
require('moment/locale/pt-br')

Vue.use(VueMaterial)
Vue.use(Vue2TouchEvents)
Vue.use(VueScrollTo, {
  container: 'body',
  duration: 500,
  easing: 'ease',
  offset: -100,
  force: true,
  x: false,
  y: true,
})
Vue.use(require('vue-moment'), { moment })

Vue.filter('date', function (value) {
  if (moment(new Date(value)).isValid()) return
  if (value) {
    try {
      return moment(value.toDate()).format('DD/MM/YYYY')
    } catch {
      return moment(new Date(value)).format('DD/MM/YYYY')
    }
  }
})

Vue.filter('datetime', function (value) {
  if (value) {
    try {
      return moment(value.toDate()).format('DD/MM/YYYY HH:mm')
    } catch {
      return moment(new Date(value)).format('DD/MM/YYYY HH:mm')
    }
  }
})

Vue.filter('truncate', function (value, maxLength) {
  if (value.length > maxLength) {
    return value.substring(0, maxLength) + '...'
  } else {
    return value
  }
})

Vue.config.productionTip = false

new Vue({
  i18n,
  store,
  router,
  apolloProvider,
  created() {
    const getHTMLTag = document.documentElement
    getHTMLTag.setAttribute('lang', localStorage.getItem('lang'))
  },
  render: (h) => h(App),
}).$mount('#app')

Vue.config.devtools = !isProduction
Vue.config.debug = !isProduction
Vue.config.silent = isProduction
