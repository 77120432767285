export default {
  name: 'Footer',
  props: ['theme'],
  data() {
    return {
      menu: [
        {
          name: 'FAQ',
          localeKey: 'FAQ',
          to: '/faq',
        },
        {
          name: 'Termos de uso',
          localeKey: 'TERMS_OF_USE',
          to: '/terms',
        },
        {
          name: 'Políticas de privacidade',
          localeKey: 'PRIVACY_POLICY',
          to: '/privacy',
        },
      ],
    }
  },
  computed: {
    formattedDate() {
      return new Date()
    },
  },
}
