import { firebaseServiceAPI as api } from '../plugins/apis'

const FirebaseService = {
  encodeReport: async (payload) => {
    return api.post('/reports/draft', payload)
  },

  decodeReport: async (token) => {
    return api.get('/reports/draft', { params: { token } })
  },

  upload: async (payload) => {
    return api.post('/file/upload/signed', payload)
  },

  getS3File: async (file) => {
    return api.get('/file/', { params: { file } })
  },

  destroyS3File: async (file) => {
    return api.delete('/file', { params: { file } })
  },

  sendSMS: async ({ body, mobilePhoneNumber }) => {
    return api.post('/notifications/send-sms', { body, mobilePhoneNumber })
  },
}

export default FirebaseService
