import { FirebaseService } from '../../services'
import Logger from '../../plugins/logger'

const actions = {
  // encode pre report
  async encodePreReport({ commit }, payload) {
    return FirebaseService.encodeReport(payload)
      .then(({ data: { token } }) => token)
      .catch((err) => {
        Logger.error('Error encoding report', { err })
        commit('setError', err)
      })
  },

  // decode pre report
  async decodePreReport({ commit }, payload) {
    return FirebaseService.decodeReport(payload)
      .then(({ data }) => data)
      .catch((err) => {
        Logger.error('Error decoding report', { err })
        commit('setError', err)
      })
  },

  // preSigned URL used to upload to S3
  async getSignedUrl({ commit }, payload) {
    const { data } = await FirebaseService.upload(payload).catch((err) => {
      Logger.error('Error on get signed url', { err })
      commit('setError', err)
    })
    return data
  },

  async getS3FileUrl({ commit }, fileKey) {
    return FirebaseService.getS3File(fileKey)
      .then(({ data }) => data)
      .catch((err) => {
        Logger.error('Error retrieving file from s3', { err })
        commit('setError', err)
      })
  },

  async destroyS3File({ commit }, payload) {
    return FirebaseService.destroyS3File(payload).catch((err) => {
      Logger.error('Error removing file', { err })
      commit('setError', err)
    })
  },

  async searchUserByEmail({ commit }, email) {
    commit('setLoading', true)
    return FirebaseService.searchUser(email).catch((err) => {
      Logger.error('Error searching for user', { err })
      commit('setError', err)
    })
  },
}

export default actions
