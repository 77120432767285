import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/analytics'
import 'firebase/functions'
import 'firebase/app-check'

import store from '../store'

const Timestamp = firebase.firestore.Timestamp
const FieldValue = firebase.firestore.FieldValue
const docIdField = firebase.firestore.FieldPath?.documentId()

const {
  VUE_APP_FIREBASE_API_KEY,
  VUE_APP_FIREBASE_AUTH_DOMAIN,
  VUE_APP_FIREBASE_DB_URL,
  VUE_APP_FIREBASE_PROJECT_ID,
  VUE_APP_FIREBASE_APP_ID,
  VUE_APP_FIREBASE_EMULATOR,
  VUE_APP_RECAPTCHA_CLIENT_KEY,
} = process.env

const config = {
  apiKey: VUE_APP_FIREBASE_API_KEY,
  authDomain: VUE_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: VUE_APP_FIREBASE_DB_URL,
  projectId: VUE_APP_FIREBASE_PROJECT_ID,
  appId: VUE_APP_FIREBASE_APP_ID,
}

if (VUE_APP_FIREBASE_EMULATOR === 'true') {
  const database = config.projectId + '-default-rtdb'
  config.databaseURL = `http://localhost:9000?ns=${database}`
}

const FirebaseAppInstance = firebase.initializeApp(config)

const DB = FirebaseAppInstance.firestore()
const Auth = FirebaseAppInstance.auth()
const Functions = FirebaseAppInstance.functions()
const Analytics = firebase.analytics()
const AppCheck = firebase.appCheck()
AppCheck.activate(VUE_APP_RECAPTCHA_CLIENT_KEY)

const trackEvent = (name, data) => Analytics.logEvent(name, data)

FirebaseAppInstance.getCurrentUser = () => {
  return new Promise((resolve, reject) => {
    Auth.onAuthStateChanged((user) => {
      if (!user?.isAnonymous) {
        resolve(user)
      }
      resolve()
    }, reject)
  })
}

if (VUE_APP_FIREBASE_EMULATOR === 'true') {
  Auth.useEmulator('http://localhost:9099/')
  DB.settings({ host: 'localhost:8088', ssl: false })
  Functions.useEmulator('localhost', 5001)
}

Auth.onAuthStateChanged(() => store.dispatch('getUserToken'))

export default FirebaseAppInstance
export { DB, Auth, Functions, Timestamp, FieldValue, trackEvent, docIdField }
