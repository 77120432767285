import gql from 'graphql-tag'

import createReport from './CREATE_REPORT.gql'
import createReportNotificationChannel from './CREATE_REPORT_NOTIFICATION_CHANNEL.gql'
import sendArchivedReport from './SEND_ARCHIVED_REPORT.gql'
import sendPendingConnectReport from './SEND_PENDING_CONNECT_REPORT.gql'

const CREATE_REPORT = gql`
  ${createReport}
`

const CREATE_REPORT_NOTIFICATION_CHANNEL = gql`
  ${createReportNotificationChannel}
`

const SEND_ARCHIVED_REPORT = gql`
  ${sendArchivedReport}
`

const SEND_PENDING_CONNECT_REPORT = gql`
  ${sendPendingConnectReport}
`

export {
  CREATE_REPORT,
  CREATE_REPORT_NOTIFICATION_CHANNEL,
  SEND_ARCHIVED_REPORT,
  SEND_PENDING_CONNECT_REPORT,
}
