import { mapState, mapMutations, mapActions, mapGetters } from 'vuex'

import SContainer from '../../components/ui/s-container'
import SButton from '../../components/ui/s-button'
import logoImage from '../../assets/images/safespace_logo.png'
import logoutImage from '../../assets/images/icone_sair_branco.svg'

export default {
  name: 'Header',
  props: ['theme'],

  components: {
    SContainer,
    SButton,
  },

  data() {
    return {
      logoImage,
      logoutImage,
      showMenu: false,
      menuItems: [
        {
          to: '/app',
          key: 'MY_SPACE',
          isVisible: true,
        },
        {
          to: '/content',
          key: 'CONTENT',
          isVisible: true,
        },
        {
          to: '/settings',
          key: 'SETTINGS',
          isVisible: true,
        },
      ],
      availableLocales: ['pt-BR', 'en', 'es'],
    }
  },

  mounted() {
    this.loadAvailableLocales()
    this.getLangByUrl()
  },

  computed: {
    ...mapState(['company', 'currentUser', 'config']),
    ...mapGetters(['isFeatureEnabled', 'isIssueFeatureEnabled']),

    hamburgerClass() {
      return this.showMenu ? 'open' : 'closed'
    },

    isDisclosureEnabled() {
      return this.isFeatureEnabled('disclosure')
    },

    isPoliciesEnabled() {
      return this.isFeatureEnabled('documentsManagement')
    },

    menuItemsFiltered() {
      return this.menuItems.filter((item) => item.isVisible)
    },

    selectedLocale() {
      return this.$i18n.locale
    },

    companyRoute() {
      const { slug, slugId } = this.company

      return { name: 'company', params: { slug, slugId } }
    },

    rootPage() {
      const { slug, slugId } = this.company || {}
      const [source] = this.collaboratorRelations() || []

      if (slug && slugId && this.isLoggedIn()) {
        return {
          name: 'company-home',
          params: { agree: true, source: source?.key, slug, slugId },
        }
      }

      return slug && slugId ? this.companyRoute : { name: 'welcome' }
    },

    showLanguageOptions() {
      return this.isFeatureEnabled('locales')
    },

    issuesPage() {
      const { slug, slugId } = this.company
      return { name: 'issues', params: { slug, slugId } }
    },

    disclosurePage() {
      const { slug, slugId } = this.company
      return `/company/${slug}/${slugId}/disclosure`
    },

    policiesPage() {
      const { slug, slugId } = this.company
      return `/company/${slug}/${slugId}/policies`
    },
  },

  methods: {
    ...mapActions(['logout']),
    ...mapMutations(['setLocale']),
    ...mapGetters(['collaboratorRelations', 'isLoggedIn']),

    newReport() {
      this.$router.push({ name: 'new-report' })
    },

    async signOut() {
      localStorage.clear()
      sessionStorage.clear()

      await this.logout()

      window.location.reload()
    },

    toggleMenu({ target }) {
      const hasClickedLogoutLink = target.classList.contains('header-logout')

      if (hasClickedLogoutLink) {
        this.showMenu = false
      }

      this.showMenu = !this.showMenu
    },

    setLanguage(locale) {
      localStorage.setItem('lang', locale)
      this.$i18n.locale = locale
      this.setLocale(locale)
      const getHTMLTag = document.documentElement
      getHTMLTag.setAttribute('lang', locale)
    },

    loadAvailableLocales() {
      const { languages = this.availableLocales } = this.config
      this.availableLocales = languages
    },

    getLangByUrl() {
      const queryParams = new URLSearchParams(window.location.search)
      let lang = queryParams?.get('lang')

      if (lang === 'pt') {
        lang = 'pt-BR'
      }

      if (lang && this.availableLocales.includes(lang)) {
        this.setLocale(lang)
      }
    },
  },
}
