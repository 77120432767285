export default {
  name: 's-icon',

  props: {
    image: {
      type: String,
      default: '',
    },
    xsmall: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
    medium: {
      type: Boolean,
      default: false,
    },
    large: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    iconSize() {
      if (this.xsmall) return 'xsmall'
      else if (this.small) return 'small'
      else if (this.medium) return 'medium'
      else if (this.large) return 'large'

      return 'medium'
    },
  },
}
