import Vue from 'vue'
import VueI18n from 'vue-i18n'

let i18nModule

if (process.env.NODE_ENV === 'test') {
  i18nModule = {
    t: (key) => key,
  }
} else {
  Vue.use(VueI18n)

  const loadLocaleMessages = () => {
    const locales = require.context(
      '../locales',
      true,
      /[A-Za-z0-9-_,\s]+\.json$/i,
    )
    const messages = {}

    locales.keys().forEach((key) => {
      const matched = key.match(/([A-Za-z0-9-_]+)\./i)
      if (matched && matched.length > 1) {
        const locale = matched[1]
        messages[locale] = locales(key)
      }
    })

    return messages
  }

  i18nModule = new VueI18n({
    locale: process.env.VUE_APP_I18N_LOCALE || 'pt-BR',
    fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'pt-BR',
    silentFallbackWarn: true,
    silentTranslationWarn: true,
    messages: loadLocaleMessages(),
  })
}

export default i18nModule
