export const getters = {
  isLoggedIn: ({ currentUser }) => {
    return Boolean(currentUser && currentUser.email) || false
  },

  filterRelationsBy:
    ({ relations }) =>
    (field) => {
      const relationsOptions = Object.keys(relations).map((relation) => {
        return {
          key: relation,
          ...relations[relation],
        }
      })

      const orderBy = (fisrtIndex, secondIndex) =>
        fisrtIndex.order < secondIndex.order ? -1 : 0

      return relationsOptions
        .filter((relation) => relation[field])
        .sort(orderBy)
    },

  enabledRelations: (_, { filterRelationsBy }) => {
    return filterRelationsBy('enabled')
  },

  collaboratorRelations: (_, { filterRelationsBy }) => {
    return filterRelationsBy('isCollaborator')
  },

  isSourceCollaborator:
    ({ relations }) =>
    (relationship) => {
      if (!relationship) {
        return false
      }

      const relation = relations[relationship]

      if (!relation) {
        return false
      }

      return relation.isCollaborator
    },

  hasMultipleCollaboratorsTypes: (_, { collaboratorRelations }) => {
    return Object.keys(collaboratorRelations).length > 1
  },

  addFollowUp:
    ({ followups }) =>
    (followUp) =>
      followups.push(followUp),

  isConnectEnabled: ({ config }) => {
    const { connect = false } = config?.features || {}
    return connect
  },

  isIssueFeatureEnabled: ({ company }) => {
    return company?.config?.features?.issues ?? true
  },

  isFeatureEnabled:
    ({ config }) =>
    (feature) => {
      const { features = {} } = config
      return features[feature] || false
    },

  experiments: (state) => state.experiments,
}
