export const createFormReportState = ({ anonymous, channel, source }) => ({
  accusedSource: null,
  anonymous: anonymous || false,
  archive: false,
  areas: [],
  channel,
  code: null,
  contactEmail: null,
  companyBranch: null,
  customArea: null,
  customInvolved: [],
  customMisconduct: null,
  description: null,
  files: [],
  happenedAt: null,
  happenedTo: null,
  involved: [],
  local: null,
  match: false,
  misconducts: [],
  observations: null,
  recurrent: null,
  safety: 0,
  source: source || 'COLLABORATOR',
  witnesses: null,
  workShift: null,
})
