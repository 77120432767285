import { apolloClient as graphql } from '@/plugins/apolloProvider'
import Logger from '@/plugins/logger'
import { isNullOrEmpty } from '@/plugins/helper'
import Metrics from '@/plugins/metrics'
import { TOKEN } from '@/globals'

import {
  CREATE_REPORT,
  CREATE_REPORT_NOTIFICATION_CHANNEL,
} from '@/graphql/mutations'
import {
  GET_AREAS,
  GET_EMPLOYEES,
  GET_MISCONDUCTS,
  GET_REPORTS,
  GET_NEXT_CODE,
  GET_REPORT_BY_CODE,
  GET_REPORT_BY_ID,
} from '@/graphql/queries'

export default {
  getReportById: async (_, reportId) => {
    const {
      data: { reportByIdOnlyColab: report },
    } = await graphql.query({
      query: GET_REPORT_BY_ID,
      variables: { id: reportId },
      fetchPolicy: 'no-cache',
    })

    return report
  },

  getAreas: async () => {
    const {
      data: { areas },
    } = await graphql.query({ query: GET_AREAS })

    return areas
  },

  getMisconducts: async () => {
    const {
      data: { misconducts },
    } = await graphql.query({ query: GET_MISCONDUCTS })

    return misconducts
  },

  createReport: async ({ commit, dispatch }, params) => {
    commit('setLoading', true)
    const { happenedAt, customInvolved } = params

    try {
      const sanitizedVariables = {
        ...params,
        customInvolved: customInvolved.filter((item) => !isNullOrEmpty(item)),
        happenedAt: happenedAt === 'NOT_SURE' ? null : happenedAt,
      }

      const response = await graphql.mutate({
        mutation: CREATE_REPORT,
        variables: sanitizedVariables,
      })

      if (response) {
        const {
          data: { createReport: report },
          errors,
        } = response

        dispatch('handleSubmitReport', params)
        commit('setReport', report)

        if (report) {
          const { source, channel, anonymous } = report
          const payloadMetrics = {
            source,
            channel,
            anonymous,
          }
          Metrics.track('Report Sent Success', payloadMetrics)
        } else {
          Metrics.track('Report Not Sent Error', errors)
        }

        report.code = params.code
        return report
      }
    } catch (error) {
      Logger.error(error.message, { code: params.code })
      commit('setError', error.message)
      commit('setLoading', false)

      Metrics.track('Report Not Sent Error', {
        error: error.message,
      })

      return false
    }
  },

  createReportNotificationChannel: async ({ commit }, params) => {
    commit('setLoading', true)

    try {
      const response = await graphql.mutate({
        mutation: CREATE_REPORT_NOTIFICATION_CHANNEL,
        variables: params,
      })

      if (response) {
        const {
          data: { createReportNotificationChannel: notification },
        } = response

        commit('setNotificationChannel', notification)

        return notification
      }
    } catch (error) {
      Logger.error(error.message, { code: params.code })
      commit('setError', error.message)
      commit('setLoading', false)

      return false
    }
  },

  getEmployees: async (_, { term } = {}) => {
    const {
      data: { searchEmployees: employees },
    } = await graphql.query({
      query: GET_EMPLOYEES,
      variables: { term },
      debounce: 1000,
    })

    return employees.data
  },

  getReports: async ({ commit, dispatch }, page) => {
    commit('setLoading', true)

    if (!localStorage.getItem(TOKEN)) {
      await dispatch('getUserToken')
    }

    const {
      data: {
        reportsByEmployee: { data: reports, pagination },
      },
    } = await graphql
      .query({
        query: GET_REPORTS,
        variables: { page },
      })
      .finally(() => commit('setLoading', false))

    return { reports, pagination }
  },

  getArchivedReports: async ({ commit }, page) => {
    commit('setLoading', true)

    const {
      data: {
        reportsByEmployee: { data: reports, pagination },
      },
    } = await graphql
      .query({
        query: GET_REPORTS,
        variables: {
          statuses: ['ARCHIVED'],
          page,
        },
      })
      .finally(() => commit('setLoading', false))

    return { reports, pagination }
  },

  getPendingReports: async ({ commit }, page) => {
    commit('setLoading', true)

    const {
      data: {
        reportsByEmployee: { data: reports, pagination },
      },
    } = await graphql
      .query({
        query: GET_REPORTS,
        variables: {
          statuses: ['PENDING_MATCH'],
          page,
        },
        fetchPolicy: 'no-cache',
      })
      .finally(() => commit('setLoading', false))

    return { reports, pagination }
  },

  async getNextCode({ commit }) {
    commit('setLoading', true)

    const {
      data: { protocolize: code },
    } = await graphql
      .query({
        query: GET_NEXT_CODE,
        fetchPolicy: 'no-cache',
      })
      .catch((error) => commit('setError', error.message))

    commit('setLoading', false)
    return code
  },

  getReportByCode: async ({ commit }, { code }) => {
    commit('setLoading', true)

    const {
      data: { reportByCode: report },
    } = await graphql
      .query({
        query: GET_REPORT_BY_CODE,
        variables: { code },
      })
      .catch((err) => {
        Logger.error(err)
        commit('setError', 'report-not-found')
      })
      .finally(() => commit('setLoading', false))

    report.code = code
    return report
  },
}
