export default {
  name: 's-container',

  props: {
    large: {
      type: Boolean,
      required: false,
    },
    fluid: {
      type: Boolean,
      required: false,
    },
  },

  computed: {
    containerStyle() {
      const styles = ['container']
      if (this.large) styles.push('large')
      if (this.fluid) styles.push('fluid')

      return styles
    },
  },
}
