export default {
  name: 's-button',

  props: {
    btnStyle: {
      type: String,
      default: 'sea',
    },
    id: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'button',
    },
    icon: String,
  },

  computed: {
    classes() {
      return ['s-button', this.btnStyle, { 'has-icon': this.icon }]
    },
  },
}
