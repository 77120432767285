import gql from 'graphql-tag'

import getAreas from './GET_AREAS.gql'
import getEmployees from './GET_EMPLOYEES.gql'
import getMisconducts from './GET_MISCONDUCTS.gql'
import getReports from './GET_REPORTS.gql'
import getReportByCode from './GET_REPORT_BY_CODE.gql'
import getNextCode from './GET_NEXT_CODE.gql'
import getReportById from './GET_REPORT_BY_ID.gql'

const GET_AREAS = gql`
  ${getAreas}
`

const GET_EMPLOYEES = gql`
  ${getEmployees}
`

const GET_MISCONDUCTS = gql`
  ${getMisconducts}
`

const GET_REPORTS = gql`
  ${getReports}
`
const GET_REPORT_BY_ID = gql`
  ${getReportById}
`

const GET_NEXT_CODE = gql`
  ${getNextCode}
`
const GET_REPORT_BY_CODE = gql`
  ${getReportByCode}
`

export {
  GET_AREAS,
  GET_EMPLOYEES,
  GET_MISCONDUCTS,
  GET_REPORTS,
  GET_REPORT_BY_ID,
  GET_REPORT_BY_CODE,
  GET_NEXT_CODE,
}
