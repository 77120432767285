import { DB } from '../plugins/firebase'
import Logger from '../plugins/logger'

export const findCompanyById = async (companyId) => {
  const snap = await DB.collection('companies')
    .doc(companyId)
    .get()
    .catch((err) => {
      Logger.error('Error retrieving company', err)
      throw new Error(err.code)
    })

  if (!snap.exists) {
    return new Error('NOT_FOUND')
  }

  return parseCompanySnapshot(snap)
}

export const findCompanyByTenant = async (tenant) => {
  const {
    docs: [company],
    empty: companyNotFound,
  } = await DB.collection('companies')
    .where('tenant', '==', tenant)
    .limit(1)
    .get()
    .catch((err) => {
      Logger.error('Error retrieving company', err)
      throw new Error(err.code)
    })

  if (companyNotFound) {
    return new Error('NOT_FOUND')
  }

  return parseCompanySnapshot(company)
}

export const findCompanyBySlug = async (slugUrl) => {
  if (!slugUrl) {
    throw new Error('NOT_FOUND')
  }

  const {
    docs: [company],
    empty: companyNotFound,
  } = await DB.collection('companies')
    .where('slug', '==', slugUrl)
    .limit(1)
    .get()
    .catch((err) => {
      Logger.error('Error retrieving company by slug', err)
      throw new Error(err.code)
    })

  if (companyNotFound) {
    return new Error('NOT_FOUND')
  }

  return parseCompanySnapshot(company)
}

const parseCompanySnapshot = (snapshot) => {
  const {
    active = true,
    config,
    defaultLanguage = 'pt',
    logoImg,
    name,
    relations,
    schemaName,
    slug,
    slugId,
    tenant,
    tenantId,
  } = snapshot.data()

  if (!active) {
    return new Error('INACTIVE_COMPANY')
  }

  return {
    id: snapshot.id,
    active,
    config,
    defaultLanguage,
    logoImg,
    name,
    relations,
    schemaName,
    slug,
    slugId,
    tenant,
    tenantId,
  }
}
