<template>
  <section class="maintenance">
    <s-title>{{ $t('MAINTENANCE_TITLE') }}</s-title>
    <p>{{ $t('MAINTENANCE_SUBTITLE') }}</p>

    <p>
      {{ $t('MAINTENANCE_TEXT') }}
    </p>

    <s-link
      trigger
      btn-style="sea medium"
      link-to="https://safe.space/conteudo"
    >
      {{ $t('BLOG_CTA') }}
    </s-link>

    <img
      :src="maintenanceImg"
      :alt="$t('MAINTENANCE_TITLE')"
      class="maintenance-img"
    />
  </section>
</template>

<script>
import SLink from './ui/s-link'
import STitle from './ui/s-title'

import maintenanceImg from '../assets/images/maintenance.svg'

export default {
  name: 'Maintenance',

  components: {
    SLink,
    STitle,
  },

  data() {
    return {
      maintenanceImg,
    }
  },
}
</script>

<style>
.maintenance {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: var(--default-height);
  justify-content: center;
  margin: 0 auto;
  max-width: 420px;
  padding: 0 20px;
  text-align: center;
  height: 90vh;
}

.maintenance h2 {
  color: var(--color-blue-default);
  font-weight: 600;
  line-height: 28px;
}

.maintenance .maintenance-img {
  width: 100%;
}

.maintenance a.s-link {
  width: 100%;
  max-width: 300px;
}
</style>
