import { Auth, DB, Timestamp } from '../../plugins/firebase'
import map from 'lodash/map'
import i18n from '../../plugins/i18n'
import Logger from '../../plugins/logger'

const actions = {
  async replyfollowUp({ commit, state, dispatch, getters }, data) {
    commit('mutate', { property: 'loading', value: true })
    commit('mutate', { property: 'notice', value: null })
    const _username = state.username || state.email || i18n.t('ANONYMOUS')

    if (!data.message || !state.companyId) {
      return
    }

    const companyRef = DB.collection('companies').doc(state.companyId)

    const payload = {
      message: data.message,
      type: data?.type || 'REPLY',
      source: 'USER',
      identity: data.report.isAnonymous ? i18n.t('ANONYMOUS') : _username,
      reportId: data.report.id,
      companyRef: companyRef,
      sentAt: Timestamp.fromDate(new Date()),
      userUID: data.report.isAnonymous ? null : Auth.currentUser?.uid,
      read: false,
    }

    const snapShotFollowUps = await DB.collection('follow_ups')
      .add(payload)
      .then(() => getters.addFollowUp(payload))
      .catch((err) => {
        Logger.error('Error creating follow up message', { err, payload })
        commit('setError', err.message)
      })
      .finally(() => commit('mutate', { property: 'loading', value: false }))

    if (data.type === 'FILE') {
      dispatch('attachFileToFollowUp', {
        key: data.message,
        followUpRef: snapShotFollowUps,
      })
    }

    commit('mutate', { property: 'notice', value: 'MESSAGE_SENT' })
    commit('setError', null)
  },

  async getFollowUps({ state, commit }, reportId) {
    try {
      const companyRef = DB.collection('companies').doc(state.companyId)

      const snap = await DB.collection('follow_ups')
        .orderBy('sentAt', 'asc')
        .where('reportId', '==', reportId)
        .where('companyRef', '==', companyRef)
        .get()

      const followups = map(snap.docs, (doc) => ({ id: doc.id, ...doc.data() }))

      commit('mutate', { property: 'followups', value: followups })

      return followups
    } catch (err) {
      Logger.error(
        'Error loading follow up messages',
        {
          code: err?.code,
          message: err.message,
        },
        'store/actions/followup/getFollowUps',
      )
      commit('setError', i18n.t(err.code))
      throw err.message
    }
  },

  async markAsRead({ commit }, followUpId) {
    await DB.collection('follow_ups')
      .doc(followUpId)
      .update({ read: true })
      .catch((err) => {
        Logger.error(
          'Error updating follow up messages',
          {
            code: err?.code,
            message: err.message,
          },
          'store/actions/followup/markAsRead',
        )
        commit('setError', i18n.t(err.code))
        throw err.message
      })
  },

  async attachFileToFollowUp({ commit }, payload) {
    const [ext] = payload.key.split('.').slice(-1)
    await DB.collection('files')
      .add({
        key: payload.key,
        followUpRef: payload.followUpRef,
        ext,
      })
      .catch((err) => {
        Logger.error(
          'Error attaching follow up file',
          {
            code: err?.code,
            message: err.message,
          },
          'store/actions/followup/attachFileToFollowUp',
        )
        commit('setError', i18n.t(err.code))
        throw err.message
      })
  },
}

export default actions
