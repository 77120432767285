export default {
  name: 's-title',

  props: {
    small: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
}
