export default {
  name: 's-link',
  props: {
    btnStyle: {
      type: String,
      default: 'primary',
    },
    linkTo: {
      type: [String, Object],
      default: '!#',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    trigger: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    isTrigger() {
      return (
        this.trigger ||
        this.linkTo == '!#' ||
        Object.keys(this.$listeners).some(String)
      )
    },
  },
}
