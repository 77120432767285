import isObject from 'lodash/isObject'
import isArray from 'lodash/isArray'
import isEmpty from 'lodash/isEmpty'
import isNull from 'lodash/isNull'
import isUndefined from 'lodash/isUndefined'

export const Protocolizer = () => {
  const gen = (min, max) =>
    max++ && [...Array(max - min)].map((_, i) => String.fromCharCode(min + i))

  const sets = { num: gen(48, 57), alpha: gen(65, 90) }

  function* iter(set) {
    for (let i = 0; i < 5; i++) yield set[(Math.random() * set?.length) | 0]
  }

  return `${[...iter(sets.alpha)].join('')}-${[...iter(sets.num)].join('')}`
}

const emailRgx =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export const EmailValidator = (email) => emailRgx.test(email)

export const isNullOrEmpty = (value) => {
  if (isUndefined(value) || isNull(value)) return true

  if (isArray(value) || isObject(value)) {
    return isEmpty(value)
  }

  return isNull(value)
}
