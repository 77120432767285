const AUTH_TENANT_ID = 's:tenantId'
const CODE = 's:sprc'
const CONFIG = 's:cnf'
const RECAPTCHA_SCORE = 0.8
const TOKEN = 's:token'
const REPORT_FLOW_EXPERIMENT = 's:report-flow-experiment'

export {
  AUTH_TENANT_ID,
  CODE,
  CONFIG,
  RECAPTCHA_SCORE,
  TOKEN,
  REPORT_FLOW_EXPERIMENT,
}
