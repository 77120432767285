import { mapState, mapActions, mapMutations } from 'vuex'
import { Auth, trackEvent } from '../../../plugins/firebase'
import router from '../../../plugins/router'
import Password from 'vue-password-strength-meter'
import { CODE } from '../../../globals'

import Agreements from '../../../components/Agreements'
import SIcon from '../../../components/ui/s-icon'
import SButton from '../../../components/ui/s-button'
import SContainer from '../../../components/ui/s-container'
import iconeCofre from '../../../assets/images/icone_cofre.svg'

export default {
  name: 'PasswordSetup',
  components: {
    Agreements,
    Password,
    SIcon,
    SButton,
    SContainer,
  },

  beforeCreate() {
    const searchParams = new URLSearchParams(window.location.search)
    searchParams.delete('apiKey')

    const continueUrl = searchParams.get('continueUrl')
    searchParams.delete('continueUrl')

    if (continueUrl) {
      if (continueUrl.match(/admin.safe.space|staging-adm/)) {
        const queryParams = searchParams.toString()
        window.location.href = `${continueUrl}?${queryParams}`
      }
    }
  },

  async created() {
    if (Auth.currentUser && !Auth.currentUser.isAnonymous) {
      router.push({ name: 'my-space' })
    } else if (Auth.currentUser?.isAnonymous) {
      await Auth.signOut()
    }

    const searchParams = new URLSearchParams(window.location.search)

    this.target = searchParams.get('t')
    if (this.target) {
      trackEvent('onboarding_cta_click', { target: this.target })
    }

    const source = searchParams.get('utm_source')
    if (source) trackEvent('source', source)

    this.actionCode = searchParams.get('oobCode')

    if (this.actionCode) await this.verify()
  },

  data() {
    return {
      acceptance: false,
      actionCode: '',
      email: '',
      emailConfirmation: '',
      iconeCofre,
      password: null,
      score: 0,
      target: '',
      valid: null,
      verification_code: '',
    }
  },

  methods: {
    ...mapActions(['passwordSetup']),
    ...mapMutations(['setError', 'setLoading']),

    setScore(score) {
      this.score = score
      this.valid = this.score >= 3
    },

    async submit() {
      this.setLoading(true)
      if (this.email && this.valid) {
        await this.passwordSetup({
          email: this.email,
          code: this.actionCode,
          secret: this.password,
        })
      }
    },

    async verify() {
      this.setLoading(true)
      await Auth.verifyPasswordResetCode(this.actionCode)
        .then((email) => (this.email = email))
        .catch((error) => {
          if (error.code.match(/invalid-action-code|expired-action-code/)) {
            this.setError(this.$t(error.code))
            router.push(`/register?err=${error.code}`)
          }
        })
        .finally(() => this.setLoading(false))
    },
  },

  computed: {
    ...mapState(['error', 'notice', 'loading', 'success', 'company']),

    isDisabled() {
      return !this.acceptance || !this.valid || this.loading
    },
  },

  watch: {
    success() {
      if (localStorage.getItem(CODE)) {
        router.push({
          name: 'company-report',
          params: {
            agree: true,
            slug: this.company.slug,
            slugId: this.company.slugId,
            code: atob(localStorage.getItem(CODE)),
            step: 6,
            recover: true,
          },
        })
      } else {
        router.push({ name: 'my-space' })
      }
    },
  },
}
