import { mapActions, mapState } from 'vuex'
import { Auth } from '@/plugins/firebase'
import LoginForm from '@/components/LoginForm'

export default {
  name: 'Login',

  components: {
    LoginForm,
  },

  data() {
    return {
      redirectAfterLogin: '/app',
    }
  },

  computed: mapState(['company', 'config']),

  async created() {
    const { redirectTo, slug, slugId } = this.$route.params

    if (slug && slugId) {
      await this.getCompanyBySlug({ slug, slugId })
    }

    if (redirectTo) {
      this.redirectAfterLogin = redirectTo
    }

    if (Auth.currentUser && !Auth.currentUser.isAnonymous) {
      this.$router.push(this.redirectAfterLogin)
    }
  },

  methods: {
    ...mapActions(['getCompanyBySlug']),

    redirectBackTo() {
      this.$router.push(this.redirectAfterLogin)
    },
  },
}
