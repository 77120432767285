import { companyAPI as api } from '../plugins/apis'

const CompanyAPI = {
  search: async (term) => {
    return api.get(`/companies?term=${term}`)
  },

  getCompanyLogo: async (companyId, logoImgKey) => {
    try {
      const response = await api
        .get(`/companies/${companyId}/upload`, {
          params: { key: logoImgKey, prefix: 'logo' },
        })
        .catch(({ message }) => {
          throw new Error(message)
        })

      return response.data
    } catch (error) {
      throw new Error(error.message)
    }
  },

  getCompanyBranches: async (companyId) => {
    const data = await api
      .get(`/companies/${companyId}/branches?active=true`)
      .catch(({ message }) => {
        throw new Error(message)
      })
    return data.data
  },

  getCompanyBranchesByKey: async (companyId, key) => {
    const data = await api
      .get(`/companies/${companyId}/branches?key=${key}`)
      .catch(({ message }) => {
        throw new Error(message)
      })
    return data.data
  },

  getDocuments: async () => {
    const response = await api.get(`/documents/colab`)
    return response.data
  },

  getDocument: async (documentId) => {
    const response = await api.get(`/documents/${documentId}/colab`)
    return response.data
  },

  getDocumentFileUrl: async (companyId, key) => {
    const response = await api.get(
      `/companies/${companyId}/upload?key=${key}&prefix=policies`,
    )

    return response.data
  },

  documentRead: async (documentId) => {
    const response = await api.post(`documents/${documentId}/read`)

    return response.data
  },
}

export default CompanyAPI
