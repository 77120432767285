import { authAPI as api } from '../plugins/apis'

const AuthAPI = {
  setCustomClaims: async (payload) => {
    return api.post('/auth/claims', payload)
  },

  upsertUser: async (payload) => {
    return api.patch('/auth/sign-on', payload)
  },

  resetPassword: async ({ email }) => {
    return api.post('/auth/reset-password', { email })
  },
}

export default AuthAPI
