import { FirebaseService } from '../../services'

const _mockClientInterface = {
  sendSMS: async ({ body, to }) => {
    // eslint-disable-next-line no-console
    console.log(
      'Simulating SMS sending...',
      'Configuration used for simulation:',
      { body, to },
    )

    return { sid: 'mock-sms-vendor-example-id' }
  },
}

const _clientInteface = {
  sendSMS: async ({ body, to }) => {
    return FirebaseService.sendSMS({
      body,
      mobilePhoneNumber: to,
    })
  },
}

export const defaultClientFactory = () => {
  const env = process.env.NODE_ENV

  if (env === 'test' || env === 'development') {
    return _mockClientInterface
  } else {
    return _clientInteface
  }
}
